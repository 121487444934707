import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import {StreamerHeaderCard} from "#/src/pages/index/StreamerHeaderCard";
import {useData} from "vike-react/useData";
import {ApiGenericPageData, ApiStreamerCategory} from "#/src/definitions/ApiDefinitions";
import {useEffect, useRef, useState} from "react";
import {Sort} from "#/src/utils/sort-utils";
import {DataWrapped} from "#/src/definitions/AppDefinitions";

export function LiveStreamersHeader() {
  const {data: pageData} = useData() as DataWrapped<ApiGenericPageData>;
  const sortedCategories = Sort.byDisplayPriority(pageData.navbarData.streamerCategories);
  const [filter, setFilter] = useState<ApiStreamerCategory | null>(sortedCategories ? sortedCategories[0] as ApiStreamerCategory : null);
  const [followed, setFollowed] = useState(false);
  const swiperRef = useRef<SwiperRef>();
  const streamers = Sort.byStreamerDisplay(
    pageData.navbarData
      .streamers
      .filter(s => {
        if(followed) {
          return s.followed;
        }
        return filter != null ? s.category.id === filter.id : true;
      })
  );
  useEffect(() => {
    if(!swiperRef?.current) {
      return;
    }
    swiperRef.current.swiper.slideTo(0);
  }, [pageData, filter, followed]);
  if (pageData.navbarData.streamers.length === 0) {
    return <></>
  }

  function updateFilter(value: ApiStreamerCategory | null) {
    setFollowed(false);
    setFilter(value);
  }

  return <section className="stream-live">
    <div className="container-fluid py-3">
      <div className="row h-100">
        <div className="col-xl-2 col-xxxl-1">
          <div className="live">
            <div className="live-title">
              <div className="live-pulse green"></div>
              <h4 className="title">Live</h4>
            </div>
            <ul className="nav-live">
              {pageData.navbarData.streamers.some(s => s.followed) && <li className="nav-live-item" onClick={() => {
                setFollowed(true);
              }}>
                <a href="#" className={`nav-live-link ${followed ? "active" : ""}`}>Obserwowani</a>
              </li>}
              {sortedCategories.map((category: ApiStreamerCategory) => <li key={category.id} className={"nav-live-item"}>
                <a href="#" className={`nav-live-link ${filter === category && !followed ? "active" : ""}`} onClick={() => updateFilter(category)}>{category.name}</a>
              </li>)}
            </ul>
          </div>
        </div>
        <div className="col-xl-10 col-xxxl-11 pe-xxl-5">
          <Swiper
            direction={"horizontal"}
            loop={true}
            grabCursor={true}
            slidesPerView={4.75}
            spaceBetween={70}
            ref={swiperRef}
            breakpoints={{
              0: {slidesPerView: 1.75, spaceBetween: 10},
              340: {slidesPerView: 2, spaceBetween: 15},
              390: {slidesPerView: 2, spaceBetween: 15},
              420: {slidesPerView: 2.5, spaceBetween: 15},
              540: {slidesPerView: 3, spaceBetween: 15},
              600: {slidesPerView: 3.25, spaceBetween: 15},
              720: {slidesPerView: 3.5, spaceBetween: 15},
              820: {slidesPerView: 3.75, spaceBetween: 15},
              960: {slidesPerView: 4.25, spaceBetween: 10},
              1140: {slidesPerView: 4.5, spaceBetween: 10,},
              1240: {slidesPerView: 4.75, spaceBetween: 15,},
              1420: {slidesPerView: 5, spaceBetween: 20,},
              1600: {slidesPerView: 5.5, spaceBetween: 20,},
              1780: {slidesPerView: 6, spaceBetween: 20,},
              1900: {slidesPerView: 6.2, spaceBetween: 20,},
              2010: {slidesPerView: 6.5, spaceBetween: 20},
              2190: {slidesPerView: 7, spaceBetween: 20},
              2370: {slidesPerView: 7.5, spaceBetween: 20},
              2550: {slidesPerView: 8, spaceBetween: 20},
              2910: {slidesPerView: 8.75, spaceBetween: 20},
              3090: {slidesPerView: 9.25, spaceBetween: 20},
              3450: {slidesPerView: 10, spaceBetween: 20},
            }}
          >
            {streamers.map(streamer => <SwiperSlide className={"my-auto"} key={streamer.id}><StreamerHeaderCard streamer={streamer}/></SwiperSlide>)}
          </Swiper>
        </div>
      </div>
    </div>
  </section>
}