import {ApiSimpleUser} from "#/src/definitions/ApiDefinitions";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext} from "#/src/definitions/AppDefinitions";

export function getProfileUrl(target: ApiSimpleUser) {
  const {user} = usePageContext() as AppPageContext;
  if (!user.loggedIn || user.data!.id != target.id) {
    return `/profile/${target.id}`;
  }
  return `/my-profile`;
}