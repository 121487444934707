import {ApiStreamerGame} from "#/src/definitions/ApiDefinitions";
import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";

interface Props {
  game: ApiStreamerGame | null,
  lazy?: boolean
}

export function StreamerGameIcon({game, lazy = false}: Props) {
  if (game == null) {
    return <></>
  }
  return <img loading={lazy ? "lazy" : "eager"} src={`${Attachments.getUrl(AttachmentNamespace.GAME_ICON, game)}`} width={16} height={16} alt={game.gameName}/>
}