import React from "react";
import {ApiStreamer} from "#/src/definitions/ApiDefinitions";
import {truncateString} from "#/src/utils/string-utils";

interface Props {
  streamer: ApiStreamer
  truncate?: number
}

export function StreamerGameName({streamer, truncate}: Props) {
  if(!streamer.isLive) {
    return <span>Offline</span>
  }
  if(!streamer.gameName) {
    return <span>Nadaje na żywo</span>
  }
  return <span className={"text-nowrap"}>{truncate ? truncateString(streamer.gameName, truncate) : streamer.gameName}</span>
}