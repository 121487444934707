import {ApiStreamer} from "#/src/definitions/ApiDefinitions";
import React from "react";
import {StreamerAvatar} from "#/src/components/streamer/StreamerAvatar";
import {StreamerGameIcon} from "#/src/components/streamer/StreamerGameIcon";
import {StreamerGameName} from "#/src/components/streamer/StreamerGameName";

interface Props {
  streamer: ApiStreamer
}

export function NavbarLivestreamerDetails({streamer}: Props) {
  return <li className="livelist-item">
    <a href={`/live/${streamer.slug}`} className="stretch-link"></a>
    <div className="stream-user">
      <StreamerAvatar streamer={streamer}/>
      <div className="stream-content">
        <div className="stream-name">
          <a href={`/live/${streamer.slug}`} className="stream-link">{streamer.username}</a>
        </div>
        <div className="stream-game">
          {streamer.isLive && <StreamerGameIcon game={streamer.game}/>}
          <StreamerGameName streamer={streamer} truncate={20}/>
        </div>
      </div>
    </div>
    <div className="livelist-info">
      {streamer.isLive && <span className="badge badge-online">{streamer.viewers}</span>}
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 15L12.5 10L7.5 5" stroke="#EEF5F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
  </li>
}