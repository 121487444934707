import {ApiStreamer} from "#/src/definitions/ApiDefinitions";
import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";
import styled from "@emotion/styled";

interface StyledProps {
  background: string
}

const Avatar = styled.div<StyledProps>`
  ${props => `background-image: url('${props.background}')`}
`;

interface Props {
  streamer: ApiStreamer,
  size?: "lg" | "sm" | "xs",
  lazy?: boolean
}

export function StreamerAvatar({streamer, size = "sm"}: Props) {
  let sizing = 50;
  if(size == "lg") {
    sizing = 100;
  } else if(size == "sm") {
    sizing = 50;
  } else if(size == "xs") {
    sizing = 40;
  }
  return <Avatar className={`avatar-stream avatar-stream-${sizing} ${streamer.isLive ? "online" : ""}`} background={Attachments.getUrl(AttachmentNamespace.STREAMER, streamer)}>
    <div className={`stream-${streamer.isLive ? "online" : "offline"}-dotted`}></div>
  </Avatar>
}