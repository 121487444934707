import {ApiStreamer} from "#/src/definitions/ApiDefinitions";
import {Link} from "#/src/components/Link";
import {truncateString} from "#/src/utils/string-utils";
import {StreamerAvatar} from "#/src/components/streamer/StreamerAvatar";
import {StreamerGameIcon} from "#/src/components/streamer/StreamerGameIcon";
import {StreamerGameName} from "#/src/components/streamer/StreamerGameName";
import React from "react";

interface Props {
  streamer: ApiStreamer
}

export function StreamerHeaderCard({streamer}: Props) {
  function renderLiveData() {
    if (!streamer.isLive) {
      return <>
        <div className="stream-game">
          <span>Offline</span>
        </div>
        <div className="stream-title">
          <span>Streamer wróci niebawem!</span>
        </div>
      </>
    }
    return <>
      <div className="stream-game">
        <StreamerGameIcon game={streamer.game}/>
        <StreamerGameName streamer={streamer} truncate={25}/>
      </div>
      <div className="stream-title">
        <span>{truncateString(streamer.liveTitle, 22)}</span>
      </div>
    </>
  }

  return <Link href={`/live/${streamer.slug}`} className="stream-user">
    <StreamerAvatar size={"xs"} streamer={streamer}/>
    <div className="stream-content">
      <div className="stream-name">
        <div className="stream-link">{streamer.username}</div>
        {streamer.isLive && <span className="badge badge-online">{streamer.viewers}</span>}
      </div>
      {renderLiveData()}
    </div>
  </Link>
}