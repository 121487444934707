import {ReactElement} from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  text-decoration: none;
  cursor: pointer;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactElement,
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}

export function ClickableWrapper({children, onClick, ...otherProps}: Props) {
  return <Wrapper onClick={onClick} {...otherProps}>
    {children}
  </Wrapper>
}