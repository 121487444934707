import logoWhite from "#/src/assets/svg/logo-white.svg";
import discordBanner from "#/src/assets/svg/discord-logo.svg";
import {Link} from "#/src/components/Link";
import {useData} from "vike-react/useData";
import {ApiArticleCategory, ApiGenericPageData, ApiStreamerCategory, ApiVideoCategory} from "#/src/definitions/ApiDefinitions";
import DOMPurify from "isomorphic-dompurify";
import {DataWrapped} from "#/src/definitions/AppDefinitions";
import {Sort} from "#/src/utils/sort-utils";

export function Footer() {
  const {data} = useData<DataWrapped<ApiGenericPageData>>();
  function render() {
    return <footer className="footer-page">
      <div className="container-xxl">
        <div className="row">
          <div className="col-xl-12">
            <div className="footer-brand">
              <Link href="/"><img src={logoWhite} alt="Ja Rock"/></Link>
              <div className="footer-social">
                <span>Obserwuj nas na:</span>
                <div className="social">
                  {data.footerData.socials.map(social => {
                    return <a key={social.id} href={social.externalLink} target={"_blank"} rel={"noreferrer noopener"}
                              className={"social-link"} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(social.svgCode)}}/>
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="row">
              <div className="col-6 col-xl-2 col-md-3 col-sm-6">
                <div className="nav-footer-column">
                  <h6 className="nav-footer-title">Menu</h6>
                  <ul className="nav-footer">
                    <li className="nav-footer-item">
                      <Link href="/" className="nav-footer-link">Strona główna</Link>
                    </li>
                    <li className="nav-footer-item">
                      <a href={"/polityka-prywatnosci"} className="nav-footer-link">Polityka prywatności</a>
                    </li>
                    <li className="nav-footer-item">
                      <Link href={"/login"} className="nav-footer-link">Logowanie</Link>
                    </li>
                    <li className="nav-footer-item">
                      <Link href={"/register"} className="nav-footer-link">Rejestracja</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-3 col-sm-6">
                <div className="nav-footer-column">
                  <h6 className="nav-footer-title">Live</h6>
                  <ul className="nav-footer">
                    {renderStreamerCategories()}
                    <li className="nav-footer-item">
                      <a href={"/live"} className="nav-footer-link">Pokaż wszystko</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-3 col-sm-6">
                <div className="nav-footer-column">
                  <h6 className="nav-footer-title">Filmy</h6>
                  <ul className="nav-footer">
                    <li className="nav-footer-item">
                      <a href={"/videos"} className="nav-footer-link">Najnowsze filmy</a>
                    </li>
                    {renderVideoCategories()}
                    <li className="nav-footer-item">
                      <a href={"/videos"} className="nav-footer-link">Pokaż wszystko</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-3 col-sm-6">
                <div className="nav-footer-column">
                  <h6 className="nav-footer-title">Blog</h6>
                  <ul className="nav-footer">
                    <li className="nav-footer-item">
                      <Link href={"/blog"} className="nav-footer-link">Najnowsze artykuły</Link>
                    </li>
                    {renderArticleCategories()}
                    <li className="nav-footer-item">
                      <Link href={"/blog"} className="nav-footer-link">Pokaż wszystko</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="footer-widget footer-discord">
                  <a href={data.footerData.discordUrl} target={"_blank"} rel={"noreferrer noopener"}>
                    <img loading={"lazy"} src={discordBanner} alt="Ja Rock Discord"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="footer-bottom">
              <span>Wszystkie prawa zastrzeżone Ⓒ {new Date().getFullYear()}. <a href={"https://www.jarock.pl"}>www.jarock.pl</a></span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  }
  function renderStreamerCategories() {
    return Sort.byDisplayPriority(data.footerData.streamerCategories).map((category: ApiStreamerCategory) => {
      return <li key={category.id} className="nav-footer-item">
        <a href={`/live?category=${category.name}`} className="nav-footer-link">{category.name}</a>
      </li>
    });
  }
  function renderVideoCategories() {
    return Sort.toSorted(data.footerData.videoCategories, (a, b) => a.name.localeCompare(b.name)).map((category: ApiVideoCategory) => {
      return <li key={category.id} className="nav-footer-item">
        <a href={`/videos?category=${category.slug}`} className="nav-footer-link">{category.name}</a>
      </li>
    });
  }
  function renderArticleCategories() {
    return Sort.toSorted(data.footerData.articleCategories, (a, b) => a.name.localeCompare(b.name)).map((category: ApiArticleCategory) => {
      return <li key={category.name} className="nav-footer-item">
        <Link href={`/blog?category=${category.slug}`} className="nav-footer-link">{category.name}</Link>
      </li>
    });
  }
  return render();
}