import {ApiCreationDateSortable, ApiDisplayPrioritySortable, ApiSortOrder, ApiStreamer} from "#/src/definitions/ApiDefinitions";

export class Sort {
  static byDateOrdered<T extends ApiCreationDateSortable>(toSort: T[], orderType: ApiSortOrder | null): T[] {
    if(orderType == null) {
      return Sort.byDate(toSort);
    }
    return Sort.toSorted(toSort, (a, b) => orderType == ApiSortOrder.NEWEST ? b.creationDate - a.creationDate : a.creationDate - b.creationDate);
  }
  static byDate<T extends ApiCreationDateSortable>(toSort: T[]): T[] {
    return Sort.toSorted(toSort, (a, b) => b.creationDate - a.creationDate);
  }
  static byDisplayPriority<T extends ApiDisplayPrioritySortable>(toSort: T[]): T[] {
    return Sort.toSorted(toSort, (a, b) => a.displayPriority - b.displayPriority);
  }
  static byStreamerDisplay<T extends ApiStreamer>(toSort: T[]): T[] {
    return Sort.toSorted(toSort, (a, b) => {
      const getPriority = (streamer: ApiStreamer) => {
        if (streamer.isLive && streamer.isPromoted) return 1;
        if (streamer.isLive) return 2;
        if (!streamer.isLive && streamer.isPromoted) return 3;
        return 4;
      };

      const priorityA = getPriority(a);
      const priorityB = getPriority(b);

      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }
      if (priorityA === 1 || priorityA === 2) {
        return b.viewers - a.viewers;
      }
      return a.username.localeCompare(b.username);
    });
  }
  //toSorted alternative that works for older browsers
  static toSorted<T>(array: T[], compareFunction?: (a: T, b: T) => number): T[] {
    return [...array].sort(compareFunction);
  }
}