import React from "react";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext} from "#/src/definitions/AppDefinitions";
import {ApiNavbarOption} from "#/src/definitions/ApiDefinitions";
import styled from "@emotion/styled";

const LinkAnchor = styled.a`
    ${props => `color: ${props.color} !important;`}
    &:hover {
        color: var(--bs-primary) !important;
    }
`;

interface Props {
  option: ApiNavbarOption
}

export function LinkNavbarRoute({option}: Props) {
  const {user} = usePageContext() as AppPageContext;
  return <li className="nav-item">
    <LinkAnchor className="nav-link" target={"_blank"} href={option.url!} color={user.lightMode ? option.lightColor! : option.darkColor!}>
      {option.name}
    </LinkAnchor>
  </li>
}