export function truncateString(value: string, maxLength: number) {
  if (value.length > maxLength) {
    return value.substring(0, maxLength) + "...";
  } else {
    return value;
  }
}

export function truncateTitle(value: string) {
  return truncateString(value, 60);
}