import React from "react";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext} from "#/src/definitions/AppDefinitions";
import AppConstants from "#/src/utils/app-constants";
import Cookies from "js-cookie";
import {reload} from "vike/client/router";

export function NavbarThemeSwitch() {
  const {user} = usePageContext() as AppPageContext;
  async function onSwitch() {
    if(user.lightMode) {
      Cookies.remove(AppConstants.LIGHT_MODE_COOKIE);
    } else {
      Cookies.set(AppConstants.LIGHT_MODE_COOKIE, "true", {expires: 365});
    }
    await reload();
  }

  return <button type="button" className="btn btn-secondary-outline" id="btnSwitch" onClick={onSwitch} aria-label={"Theme switch"}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_398_3578)">
        <path
          d="M9.99996 1.66675V3.33341M9.99996 16.6667V18.3334M3.33329 10.0001H1.66663M5.26172 5.26184L4.08321 4.08333M14.7382 5.26184L15.9167 4.08333M5.26172 14.7417L4.08321 15.9203M14.7382 14.7417L15.9167 15.9203M18.3333 10.0001H16.6666M14.1666 10.0001C14.1666 12.3013 12.3011 14.1667 9.99996 14.1667C7.69877 14.1667 5.83329 12.3013 5.83329 10.0001C5.83329 7.6989 7.69877 5.83341 9.99996 5.83341C12.3011 5.83341 14.1666 7.6989 14.1666 10.0001Z"
          stroke="#EEF5F8" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_398_3578">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </button>
}