import {Sort} from "#/src/utils/sort-utils";
import {ApiGenericPageData, ApiNavbarOption, ApiNavbarOptionType} from "#/src/definitions/ApiDefinitions";
import React from "react";
import {useData} from "vike-react/useData";
import {usePageContext} from "vike-react/usePageContext";
import {LiveNavbarRoute} from "#/src/components/navbar/LiveNavbarRoute";
import {VideosNavbarRoute} from "#/src/components/navbar/VideosNavbarRoute";
import {LinkNavbarRoute} from "#/src/components/navbar/LinkNavbarRoute";
import {DataWrapped} from "#/src/definitions/AppDefinitions";

export function NavbarRouter() {
  const {data} = useData() as DataWrapped<ApiGenericPageData>;
  const {urlPathname} = usePageContext();
  const sorted = Sort.byDisplayPriority(data.navbarData.options);
  return sorted.map((navbarOption: ApiNavbarOption) => {
    switch (navbarOption.optionType) {
      case ApiNavbarOptionType.MAIN_PAGE:
        return <li key={navbarOption.id} className="nav-item">
          <a className={`nav-link ${urlPathname === "/" ? " active" : ""}`} aria-current="page" href={"/"}>{navbarOption.name}</a>
        </li>;
      case ApiNavbarOptionType.LIVE_PAGE:
        return <LiveNavbarRoute key={navbarOption.id} option={navbarOption}/>
      case ApiNavbarOptionType.BLOG_PAGE:
        return <li key={navbarOption.id} className="nav-item">
          <a className={`nav-link ${urlPathname.startsWith("/blog") ? "active" : ""}`} href={"/blog"}>{navbarOption.name}</a>
        </li>;
      case ApiNavbarOptionType.VIDEOS_PAGE:
        return <VideosNavbarRoute key={navbarOption.id} option={navbarOption}/>
      case ApiNavbarOptionType.EXTERNAL_LINK:
        return <LinkNavbarRoute key={navbarOption.id} option={navbarOption}/>;
    }
  });
}