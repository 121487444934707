import {ApiVideo} from "#/src/definitions/ApiDefinitions";
import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";
import {getProfileUrl} from "#/src/utils/profile-url";
import eye from "#/src/assets/svg/eye.svg";
import eyeWhite from "#/src/assets/svg/eye-white.svg"
import clock from "#/src/assets/svg/clock.svg";
import {Link} from "#/src/components/Link";
import {truncateTitle} from "#/src/utils/string-utils";
import {ClickableWrapper} from "#/src/components/ClickableWrapper";
import AppConstants from "#/src/utils/app-constants";
import {composeHeaders} from "#/src/utils/api-request-utils";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext} from "#/src/definitions/AppDefinitions";
import {useState} from "react";
import {navigate} from "vike/client/router";
import {VideoModal} from "#/src/components/VideoModal";
import {AvatarWithFallback} from "#/src/components/AvatarWithFallback";

interface Props {
  video: ApiVideo
  lazy?: boolean,
  isAd?: boolean,
  renderLikes?: boolean
}

export function VideoCard({video, lazy = false, renderLikes = true, isAd = false}: Props) {
  const pageContext = usePageContext() as AppPageContext;
  const {user} = pageContext;
  const [liked, setLiked] = useState(video.liked);
  const [likes, setLikes] = useState(video.likes);
  const [open, setOpen] = useState(false);

  async function onLikeToggle(e: React.MouseEvent) {
    e.stopPropagation();
    if (!user.loggedIn) {
      await navigate("/login");
      return;
    }
    const response = await fetch(`${AppConstants.API_ROUTE}/videos/${video.id}/likes`, {
      method: liked ? "DELETE" : "POST",
      headers: composeHeaders(pageContext)
    });
    if (response.ok) {
      const newLiked = !liked;
      setLiked(newLiked);
      if (newLiked) {
        setLikes(likes + 1);
      } else {
        setLikes(likes - 1);
      }
    }
  }

  function onOpen() {
    setOpen(true);
    //count organic view only after few seconds if still opened - to avoid "fake/blank" views
    setTimeout(() => {
      if (!open) {
        return;
      }
      //ignore the outcome
      fetch(`${AppConstants.API_ROUTE}/videos/${video.id}/views`, {
        method: "POST",
        headers: composeHeaders(pageContext)
      });
    }, 5000);
  }

  function render() {
    return <>
      <VideoModal src={video.embed} open={open} onClose={() => setOpen(false)}/>
      <ClickableWrapper onClick={onOpen}>
        <article className="article-card">
          <figure className="article-img article-img-xl">
            <img loading={lazy ? "lazy" : "eager"} src={getThumbnailUrl()} alt={video.title}/>
          </figure>
          <div className={"d-flex"}>
            <div className="article-content w-100">
              <div className={"d-flex"}>
                <div>
                  <div className="article-tag">
                    <Link href={`/videos${video.category.visible ? `?category=${video.category.slug}` : ""}`} className="tag-primary">{video.category.name}</Link>
                  </div>

                  <a href={"#"} className="article-link">
                    {truncateTitle(video.title)}
                  </a>
                </div>
                {renderLikes && <div className={"text-center ms-auto ps-3"}>
                  {renderLikeIcon()}
                  <small className={"mt-1 video-like-title"}>{likes}</small>
                </div>}
              </div>

              <div className="article-author mt-2">
                <div className="author">
                  <AvatarWithFallback src={Attachments.getUserAvatar(video.author)} alt={video.author.username} className={"avatar avatar-20"} lazy={lazy}/>
                  <a href={getProfileUrl(video.author)} className="author-link">{video.author.username}</a>
                </div>
                <div className="article-date text-nowrap">
                  {!video.pendingModeration && <>
                    <img src={isAd ? eyeWhite : eye} alt={"Odsłon"} className={"me-1"}/>
                    <span>{video.views} odsłon</span>
                  </>}
                  {video.pendingModeration && <span><img src={clock} alt={"Moderacja"} className={"me-1"}/> W moderacji</span>}
                </div>
              </div>
            </div>
          </div>
        </article>
      </ClickableWrapper>
    </>
  }

  function getThumbnailUrl() {
    if (video.thumbnailUrl == null) {
      return Attachments.getUrl(AttachmentNamespace.VIDEO, video);
    }
    return video.thumbnailUrl;
  }


  function renderLikeIcon() {
    if (liked) {
      return <div className={"video-liked"} onClick={onLikeToggle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
          <path className={"video-like"}
            d="M6.979 3.074a6 6 0 0 1 4.988 1.425l.037 .033l.034 -.03a6 6 0 0 1 4.733 -1.44l.246 .036a6 6 0 0 1 3.364 10.008l-.18 .185l-.048 .041l-7.45 7.379a1 1 0 0 1 -1.313 .082l-.094 -.082l-7.493 -7.422a6 6 0 0 1 3.176 -10.215z"/>
        </svg>
      </div>
    }

    return <div className={"video-not-liked"} onClick={onLikeToggle}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
           strokeLinejoin="round">
        <path className={"video-like"} d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"/>
      </svg>
    </div>
  }

  return render();
}